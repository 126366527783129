@charset 'utf-8';

@import "settings";
@import "../node_modules/foundation-sites/scss/foundation";
@import "../node_modules/motion-ui/motion-ui";

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "components/header";
@import "components/cheeseburger";

a:hover {
	color: $tohear-green;
}

body {
}

.body-wrap {
}

.hero {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		object-fit: cover;
		object-position: bottom;
		min-width: 100%;
		height: 80vh;
	}

	img.content-pic {
		height: 70vh;
		object-position: center;
	}

	a.button {
		color: $white;
		border-color: $white;
		margin-top: 3rem;
	}
}

.hero-content {
	position: absolute;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.32));
	text-align: center;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: flex;
	color: white;
}

.banner {
}

.banner-contact {
}

.logo {
}

.menu-button {
	border-radius: 1rem;
	align-self: center;
	justify-self: center;
	margin: 0;
	font-weight: bold;
	text-transform: uppercase;
}

.social-head {
	display: flex;
	justify-content: center;

	@include breakpoint(large) {
		justify-content: flex-end;
	}

	a {
		color: $warning-color;
		font-weight: bold;
		font-size: 1.2rem;
	}

	a.secondary {
		color: $tohear-green;
		font-weight: bold;
	}

	li {
		display: flex;
		align-items: center;
	}
}

#main-nav {
	z-index: -1;
}

.main-nav {
	display: none;
	padding: 1rem 1rem 2rem 1rem;
	font-size: 1.2rem;
	font-weight: bold;

	@include breakpoint(large) {
		padding: 0;
		display: flex;
		justify-content: center;
	}
}

.main-nav.open {
}

.social {
	a {
		font-size: 2rem;
	}
}

.video-hero {
	.cell {
		padding: 0;

		@include breakpoint(medium) {
			padding: 3rem 0.9375rem;
		}
	}
}

.intro-block {
	padding: 5rem 0;
}

.feature-block-wrap {
	background: rgba($primary-color, 0.1);
}

.feature-block {
	padding: 2rem 0;

	i {
		font-size: 6rem;
		color: $tohear-green;
		margin-bottom: 1rem;
	}

	.block {
		padding: 3rem;
	}
}

.basic-text {
	padding: 5rem 0;
	background: rgba($primary-color, 0.1);
}

.basic-text.white {
	background: $white;
}

.basic-text h2,
.basic-text h3 {
	text-align: center;
}

.box-4-wrap {
	background: $tohear-orange;
	color: rgba($white, 0.9);

	h2 {
		margin-bottom: 2rem;
	}

	.feature-box {
		background: $tohear-orange;
		color: rgba(white, 0.9);
		padding: 4rem;
		min-height: 300px;
		font-weight: bold;
		display: flex;
		text-align: center;
		align-items: center;
	}

	.feature-box.megaphone {
		background-image: linear-gradient(to bottom, rgba($black, 0.72), rgba($black, 0.85)),
			url("/assets/img/megaphone.jpg");
		background-size: cover;
	}

	.feature-box.strain {
		background-image: linear-gradient(to bottom, rgba($black, 0.72), rgba($black, 0.85)),
			url("/assets/img/strain.jpg");
		background-size: cover;
	}

	.feature-box.annoyed {
		background-image: linear-gradient(to bottom, rgba($black, 0.72), rgba($black, 0.85)),
			url("/assets/img/annoyed.jpg");
		background-size: cover;
	}

	.feature-box.tv {
		background-image: linear-gradient(to bottom, rgba($black, 0.72), rgba($black, 0.85)), url("/assets/img/tv.jpg");
		background-size: cover;
	}
}

.triangle.blue {
	fill: rgba(232, 238, 245, 1);
}

.video-wrap {
	// background: linear-gradient(to left, rgba($tohear-green, 1), rgba($tohear-green, .85));
	background: rgba($primary-color, 0.1);

	@include breakpoint(medium) {
		padding-left: 1rem;
		padding-bottom: 1rem;
		padding-right: 1rem;
	}

	.inner {
		background: $primary-color;
		border-bottom-right-radius: 10vw;
		@include breakpoint(medium) {
			border-top-left-radius: 10vw;
		}
	}

	h2 {
		color: rgba($white, 0.9);
	}
}

.about {
	blockquote {
		font-size: 1.3rem;
		text-align: left;
		font-weight: normal;
		border: none;
		font-style: italic;

		cite {
			color: $primary-color;
			font-style: normal;
			font-weight: bold;
			margin-top: 1rem;
		}
	}
}

.client-quote {
	// background: lighten($tohear-green, 40%);
	padding: 5rem 0;

	blockquote {
		font-size: 1.4rem;
		text-align: center;
		color: $primary-color;
		font-weight: bold;
		border: none;
		font-style: italic;

		cite {
			color: $primary-color;
			font-style: normal;
			font-weight: bold;
			margin-top: 1rem;
		}
	}
}

.content {
	padding: 3rem 0 5rem;
}

.main {
}

.cta-choices {
	text-align: center;
	padding: 2rem 0;

	.green {
		background: $tohear-green;
	}

	.orange {
		background: $tohear-orange;
	}
}

.sidebar {
	@include breakpoint(medium only) {
	}

	@include breakpoint(large) {
		padding: 2rem 3rem 2rem 0;
	}
}

.plug {
	text-align: center;
	margin-bottom: 3rem;

	img {
		padding: 1rem 0;
	}

	p {
		padding: 0 2rem;

		@include breakpoint(large) {
			padding: 0;
			text-align: justify;
		}
	}

	@include breakpoint(large) {
		text-align: left;
	}
}

q,
blockquote {
	quotes: "\201C""\201D""\2018""\2019";
}

.testimonial {
	background: rgba(0, 0, 0, 0.025);

	blockquote {
		color: rgba($primary-color, 0.1);
		font-size: 1.6rem;
		padding: 3rem 20%;

		cite {
			color: $tohear-green;
		}

		&:before {
			content: open-quote;
		}
	}
}

.cta {
	text-align: center;
	background: lighten($tohear-blue, 50%);
	padding: 3rem 1rem 2rem 1rem;
	color: $tohear-blue;

	.h2 {
		font-weight: bold;
	}

	a.button {
		color: $primary-color;
		background: $white;
	}
}

footer {
	background: $primary-color;
	padding: 1rem;
	color: $white;

	text-align: center;

	.menu a {
		color: $white;
		font-weight: bold;
	}

	.menu a:hover {
		color: $tohear-green;
	}

	.copyright {
		font-size: 0.9rem;
	}

	hr {
		border-color: rgba(255, 255, 255, 0.1);
	}
}

.news-summary {
	margin: 1rem 0 3rem 0;

	@include breakpoint(medium) {
		display: flex;
		align-items: center;
		justify-content: start;
	}

	@include breakpoint(medium) {
		.th-image {
			margin-right: 2rem;
			flex: 1 1 0;
		}
		.summary-content {
			flex: 2 2 0;
		}
	}
}

.sidebar .menu {
	background: $primary-color;
	color: rgba($white, 0.9);

	a {
		color: rgba($white, 0.9);
	}

	margin: 2rem 0;
	padding: 1rem;

	@include breakpoint(medium) {
		background: none;
		color: $primary-color;
		border-left: solid 1px $light-gray;
		padding-left: 1rem;

		a {
			color: $primary-color;
		}
	}
}

// NEWS

.feature-pic {
	margin-bottom: 2rem;
}

.news .image {
	padding: 1rem 0 2rem 0;
}

.pagination {
	margin-top: 3rem;
}

.cell.card {
	position: relative;
	padding-bottom: 3rem;
}

.read-article-block {
	display: block;
	position: absolute;
	margin-top: 2rem;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1rem 2rem;
	background: lighten($tohear-blue, 50%);
	color: $tohear-blue;
	text-align: center;
}

.sand {
	background: linear-gradient(to top, rgba($white, 0.9), rgba($white, 0.9)),
		url(/site/themes/tohear/img/concrete-bg.jpg);
	background-size: cover;
}

// TEAM

.team-box {
	background: rgba($primary-color, 0.1);
	padding-top: 2rem;

	h2 {
		margin-top: 2rem;
		margin-bottom: 2rem;
		text-align: center;
	}

	.card {
		background: none;
		text-align: center;
		border: none;
		max-width: 300px;
	}
}

.headshot {
	border-radius: 50%;
}

// HEARING SOLUTIONS

.manufacturers {
	padding: 2rem 0;
}

// ALERT BANNER

.alert-banner {
	background: $tohear-blue;

	padding: 1.5rem;
	text-align: center;

	a {
		color: $white;
		font-weight: bold;
	}

	@include breakpoint(medium) {
		background: $tohear-green;
	}
}
