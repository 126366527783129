#cheeseburger {
    width: 35px;
    height: 30px;
    position: relative;
    margin: 42px auto 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #cheeseburger span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: $primary-color;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  /* Icon 3 */
  
  #cheeseburger span:nth-child(1) {
    top: 0px;
  }
  
  #cheeseburger span:nth-child(2),#cheeseburger span:nth-child(3) {
    top: 12px;
  }
  
  #cheeseburger span:nth-child(4) {
    top: 24px;
  }
  
  #cheeseburger.open span:nth-child(1) {
    top: 12px;
    width: 0%;
    left: 50%;
  }
  
  #cheeseburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #cheeseburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #cheeseburger.open span:nth-child(4) {
    top: 12px;
    width: 0%;
    left: 50%;
  }
  
  