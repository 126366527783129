header {
	padding: .5rem;
	padding-top: 2.5rem;

	@include breakpoint(medium) {
	padding: 2rem;
	}
    border-bottom: 1px solid #e6e6e6;
    // transition: padding 0.25s ease;
	
	.social-head {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: $tohear-green;

		a {
			color: white;
		}

		@include breakpoint(medium) {
		display:none;
		}

		@include breakpoint(1188px) {
		display: flex;
		position: relative;
		background: none;
		transition: display 0.25s ease 2s;

		a {
			color: $tohear-green;
		}
		}
	}

	.header-inner {
		// transition: padding 0.25s ease;
	}

	.logo {
		transition: width 0.25s ease;
		width: 100%;
		max-width: 250px;

	}
}

// Sticky Container
header.is-stuck {
	background: $white;
	padding-top: 0;
	padding-bottom: 0;
    transition: padding 0.25s ease;


	.header-inner {
		min-height: 0;
		padding: 10px;
		transition: padding 0.25s ease;
	}

	.logo {
			width: 70%;
			transition: width 0.25s ease;
	}
}